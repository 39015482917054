import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import app from './app'
import code from './code'
import codebase from './codebase'
import devices from './devices'
import general from './general'
import notebook from './notebook'
import notebooks from './notebooks'
import settings from './settings'
import subscription from './subscription'
import user from './user'
import workspaces from './workspaces'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: { ...vuexfireMutations },
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    workspaces,
    subscription,
    code,
    devices,
    codebase,
    general,
    settings,
    notebook,
    notebooks,
  },
})
