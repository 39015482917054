<template>
  <v-card>
    <!-- {{ notification }} -->
    <v-list-item
      link
    >
      <!-- Avatar -->
      <v-list-item-avatar
        :class="[{'v-avatar-light-bg primary--text justify-center': notification.user && !notification.user.avatar}]"
        size="38"
      >
        <v-icon>
          {{ icons.mdiCashMultiple }}
        </v-icon>
      </v-list-item-avatar>

      <!-- Content -->
      <v-list-item-content class="d-block">
        <v-list-item-title class="text-sm font-weight-semibold">
          Invited to join the plan
        </v-list-item-title>
        <v-list-item-subtitle class="text-sm text-wrap">
          {{ notification.Details }}
        </v-list-item-subtitle>
        <v-list-item-title class="mt-2">
          <v-btn
            dense
            color="primary"
            class="pa-0"
            @click="acceptInvitation"
          >
            Accept
          </v-btn>
          <v-btn
            dense
            outlined
            color="secondary"
            class="ml-1 pa-0"
            @click="declineInvitation"
          >
            Decline
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>

      <!-- Item Action/Time -->
      <v-list-item-action>
        <span class="text--secondary text-xs">{{ month }}, {{ day }}</span>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import { getInitialName } from '@core/utils'
import { computed } from '@vue/composition-api'

import store from '@/store'
import { mdiCashMultiple } from '@mdi/js'

import { auth, functions } from '@/firebase/config'

export default {
  props: ['notification'],
  setup(props, { emit }) {
    const { currentUser } = auth

    const user = computed(() => store.getters['user/getUser'])

    // const time convert notification.timestamp to a month day

    const time = new Date(props.notification.CreatedOn.seconds * 1000)

    // get the current timestamp
    const month = time.toLocaleString('default', { month: 'short' })
    const day = time.getDate()

    const acceptInvitation = () => {
      const inviteMember = functions.httpsCallable('acceptInviteToPlan')

      // console.log('props.notification', props.notification)
      const result = inviteMember({
        invitedById: props.notification.Metadata.InvitedById,
      })

      result
        .then(() => {
          // console.log('Invite accepted')
          emit('removeNotification')
        })
        .catch(error => {
          // console.log('Error accepting plan', error)
        })
    }

    const declineInvitation = () => {
      // console.log('Workspace invitation declined')
      const inviteMember = functions.httpsCallable('rejectInviteToPlan')
      const result = inviteMember({
        invitedById: props.notification.Metadata.InvitedById,
        email: currentUser.email,
      })

      result
        .then(() => {
          // console.log('Invite declined')
          emit('removeNotification')
        })
        .catch(error => {
          // console.log('Error declining plan', error)
        })
    }

    return {
      user,

      getInitialName,
      month,
      day,

      acceptInvitation,
      declineInvitation,

      icons: {
        mdiCashMultiple,
      },
    }
  },
}
</script>

<style>
</style>
