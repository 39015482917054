import firebase from 'firebase/compat/app'
import 'firebase/compat/app-check'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true

const firebaseConfig = {
  // production keys
  apiKey: 'AIzaSyDRCxqhZ-WwxdN6OvpIdN9uXWhiezRF5mU',
  authDomain: 'askcodi-1a402.firebaseapp.com',
  projectId: 'askcodi-1a402',
  storageBucket: 'askcodi-1a402.appspot.com',
  messagingSenderId: '537191432561',
  appId: '1:537191432561:web:85a7c9e3a5fb12934276d0',
  measurementId: 'G-74VNRD8B1N',

  // test keys
  // apiKey: 'AIzaSyA8-LpVsnqJRueG6y8K3QMMwq1E-t5ES3k',
  // authDomain: 'askcodi-cc166.firebaseapp.com',
  // projectId: 'askcodi-cc166',
  // storageBucket: 'askcodi-cc166.appspot.com',
  // messagingSenderId: '928799841415',
  // appId: '1:928799841415:web:73983aa6a2e7e2ddb2ac22',
  // measurementId: 'G-VQZRMVLTBC',
}

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage().ref()
const realtimeDb = firebase.database().ref()
const functions = firebase.functions()

const googleProvider = new firebase.auth.GoogleAuthProvider()
const twitterProvider = new firebase.auth.TwitterAuthProvider()
const githubProvider = new firebase.auth.GithubAuthProvider()

const timestamp = firebase.firestore.Timestamp

const appCheck = firebase.appCheck()

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LeDwGMhAAAAACcT0947WcpEOnCxubHmrfsKrzRs',
  firebaseConfig,

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true,
)

export {
  firebase,
  db,
  auth,
  timestamp,
  storage,
  realtimeDb,
  functions,
  googleProvider,
  twitterProvider,
  githubProvider,
}

// export const getFirepadReference = () => {
//   let ref = firebase.database().ref()
//   const hash = window.location.hash.replace(/#/g, '')

//   if (hash) {
//     ref = ref.child(hash)
//   } else {
//     const newRef = ref.push()
//     // console.log(newRef)
//     window.location = `${window.location}#${newRef.key}`
//   }

//   return ref
// }
