<template>
  <v-card @click.stop="clearNoti">
    <!-- {{ notification }} -->
    <v-list-item
      link
    >
      <!-- Avatar -->
      <v-list-item-avatar
        :class="[{'v-avatar-light-bg primary--text justify-center': notification.user && !notification.user.avatar}]"
        size="38"
      >
        <v-icon>
          {{ icons.mdiCommentTextOutline }}
        </v-icon>
      </v-list-item-avatar>

      <!-- Content -->
      <v-list-item-content class="d-block">
        <v-list-item-subtitle class="text-sm  text-wrap">
          {{ notification.Details }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <!-- Item Action/Time -->
      <v-list-item-action>
        <span class="text--secondary text-xs">{{ month }}, {{ day }}</span>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import { getInitialName } from '@core/utils'
import { mdiCommentTextOutline } from '@mdi/js'

export default {
  props: ['notification'],
  setup(props, { emit }) {
    // console.log(props.notification)

    const time = new Date(props.notification.CreatedOn.seconds * 1000)

    // get the current timestamp
    const month = time.toLocaleString('default', { month: 'short' })
    const day = time.getDate()

    const clearNoti = () => {
      emit('removeNotification')
    }

    return {
      getInitialName,

      month,
      day,

      clearNoti,
      icons: {
        mdiCommentTextOutline,
      },
    }
  },
}
</script>

<style>
</style>
