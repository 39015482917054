import { auth, db, timestamp } from '@/firebase/config'

export default {
  namespaced: true,
  state: {
    status: 'idle',
    error: '',
    notebooks: [],
  },
  getters: {
    getNotebooks(state) {
      // return the notebook from notebooks where id === payload
      return state.notebooks

      // return state.notebooks
    },
    getState(state) {
      return state
    },
  },
  mutations: {
    ADD_NOTEBOOK(state, payload) {
      state.notebooks.push(payload)
    },
    DELETE_NOTEBOOK(state, payload) {
      state.notebooks = state.notebooks.filter(notebook => notebook.id !== payload)
    },
    SET_NOTEBOOKS(state, payload) {
      state.notebooks.push(...payload.notebooks)
    },
    UPDATE_NOTEBOOK(state, payload) {
      const notebookIndex = state.notebooks.findIndex(notebook => notebook.id === payload.id)
      if (notebookIndex !== -1) {
        state.notebooks[notebookIndex] = { ...state.notebooks[notebookIndex], ...payload }

        // update notebook at notebookIndex with the fields in payload
        // state.notebooks.splice(notebookIndex, 1, {payload})
      }
    },
    RESET_NOTEBOOKS(state) {
      state.status = 'idle'
      state.error = ''
      state.notebooks = []
    },
  },
  actions: {
    // action to fetch all notebooks from firestore
    fetchNotebooks: ({ commit }, page) => {
      const perPage = 10
      const offset = (page - 1) * perPage

      db.collection('Notebooks')
        .where('Owner', '==', auth.currentUser.uid)
        .orderBy('CreatedOn', 'desc')
        .limit(perPage)
        .get()
        .then(querySnapshot => {
          const notebooks = []
          querySnapshot.forEach(doc => {
            notebooks.push({
              id: doc.id,
              ...doc.data(),
            })
          })
          commit('SET_NOTEBOOKS', { notebooks })
        })
        .catch(error => {
          console.log('Error getting notebooks:', error)
        })

      // Filter the notebook by Owner: auth.currentUser.uid
    },

    // action to create a new notebook in firestore
    createNotebook: async ({ commit }) => {
      console.log('Notebook to be created')

      // add new notebook to firestore
      const doc = await db.collection('Notebooks').add({
        Name: 'untitled',
        CreatedOn: timestamp.now(),
        Owner: auth.currentUser.uid,
        Language: '',
      })

      if (doc) {
        console.log('notebook to be created in notebooks', doc)
        commit('ADD_NOTEBOOK', {
          id: doc.id,
          Name: 'untitled',
          CreatedOn: timestamp.now(),
          UpdatedOn: timestamp.now(),
          Owner: auth.currentUser.uid,
          Language: '',
        })

        return doc.id
      }

      // commit('SET_NOTEBOOK_ID', note.id)
    },

    // action to update an existing notebook in firestore
    updateNotebook: ({ state, commit }, notebook) => {
      // seperate the rest and id from notebook
      const { id, ...rest } = notebook
      console.log(state.notebooks, 'rest is', rest)

      db.collection('Notebooks')
        .doc(id)
        .update({ ...rest, UpdatedOn: timestamp.now() })

      commit('UPDATE_NOTEBOOK', { id: notebook.id, UpdatedOn: timestamp.now() })
    },

    // action to delete a notebook from firestore
    deleteNotebook: ({ commit }, notebookId) => {
      // delete notebook from firestore
      db.collection('Notebooks').doc(notebookId).delete()
      commit('DELETE_NOTEBOOK', notebookId)
    },
  },
}
