import { firestoreAction } from 'vuexfire'

import readIds from '@/composables/readIds'
import { db } from '@/firebase/config'

export default {
  namespaced: true,
  state: {
    devices: {
      deviceDetails: [],
      numOfDevices: 0,
    },
  },
  getters: {
    getDevices(state) {
      return state.devices
    },
  },
  mutations: {
    SET_DEVICES(state, payload) {
      payload.forEach(item => {
        state.devices.deviceDetails.push(item)
        state.devices.numOfDevices += 1
      })
    },
    RESET_ALL(state) {
      state.devices.deviceDetails = []
      state.devices.numOfDevices = 0
    },
  },
  actions: {
    bindDevices: firestoreAction(({ rootState, commit }) => {
      const { Profile } = rootState.user

      if (Profile.Devices) {
        // console.log('I am triggered in devices')
        readIds(db.collection('Devices'), Profile.Devices).then(resp => {
          commit('RESET_ALL')
          commit('SET_DEVICES', resp)
        })
      }
    }),
  },
}
