const allAbilities = [
  {
    action: 'use',
    subject: 'backend',
  },
  {
    action: 'use',
    subject: 'frontend',
  },
  {
    action: 'use',
    subject: 'documentation',
  },
  {
    action: 'use',
    subject: 'test',
  },
  {
    action: 'use',
    subject: 'codeexplainer',
  },
]

const pricingAbilities = [
  {
    action: 'create',
    subject: 'workspace',
  },
  {
    action: 'manage',
    subject: 'devices',
  },
]

const workspaceAbilities = {
  Author: [
    {
      action: 'manage',
      subject: 'workspace',
    },
  ],
  Admin: [
    {
      action: 'manage',
      subject: 'workspace',
    },
    {
      inverted: true,
      action: 'delete',
      subject: 'workspace',
    },
  ],
  Developer: [
    {
      action: 'leave',
      subject: 'workspace',
    },
  ],
}

export { allAbilities, pricingAbilities, workspaceAbilities }
