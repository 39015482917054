export default {
  namespaced: true,
  state: {
    errorMsg: '',
    errorType: 'error',
    snackbarMsg: '',
    confirmationMsg: '',
    confirmationResponse: '',
    loader: false,
  },
  getters: {
    getError(state) {
      return state.errorMsg
    },
    getErrorType(state) {
      return state.errorType
    },
    getLoaderState(state) {
      return state.loader
    },
    getSnackbarMsg(state) {
      return state.snackbarMsg
    },
    getConfirmationMsg(state) {
      return state.confirmationMsg
    },
    getConfirmationResponse(state) {
      return state.confirmationResponse
    },
  },
  mutations: {
    SET_ERROR_MSG(state, payload) {
      state.errorMsg = payload
    },
    SET_ERROR_TYPE(state, payload) {
      state.errorType = payload
    },
    SET_LOADER_STATE(state, payload) {
      state.loader = payload
    },
    SET_SNACKBAR_MSG_STATE(state, payload) {
      state.snackbarMsg = ''
      state.snackbarMsg = payload
    },
    SET_CONFIRMATION_MSG(state, payload) {
      // console.log('SETIING confirmation msg', payload)
      state.confirmationMsg = ''
      state.confirmationMsg = payload
    },
    SET_CONFIRMATION_RESPONSE(state, payload) {
      state.confirmationResponse = ''
      state.confirmationResponse = payload
    },
  },
  actions: {},
}
