<template>
  <v-form
    ref="form"
    class="text-center"
  >
    <v-dialog
      v-model="dialogOpen"
      width="500"
      :persistent="isPersistent"
    >
      <v-card>
        <v-card-title>
          Display Name
        </v-card-title>
        <v-alert
          color="primary"
          dense
          text
          class="mx-4 caption"
        >
          Please set a display name. This is to be used to save code, invite members to plans and workspace.
        </v-alert>

        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-model="displayName"
            label="Display Name"
            outlined
            hide-details
            :rules="[required]"
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="updateUserProfile"
          >
            Save display name
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

import { auth, db } from '@/firebase/config'

export default {
  props: ['openDialog', 'isPersistent'],
  setup() {
    const { currentUser } = auth
    const user = computed(() => store.getters['user/getUser'])
    const updated = computed(() => store.getters['user/getUpdated'])
    const dialogOpen = ref(false)

    const displayName = ref('')

    const form = ref(null)
    const updateUserProfile = () => {
      store.commit('general/SET_LOADER_STATE', true)

      auth.currentUser.updateProfile({ displayName: displayName.value }).then(() => {
        db.collection('Users')
          .doc(currentUser.uid)
          .update({ DisplayName: displayName.value })
          .then(() => {
            // console.log('Profile is updated')
            dialogOpen.value = false
            store.commit('general/SET_SNACKBAR_MSG_STATE', 'Your display name is updated')
            store.commit('general/SET_LOADER_STATE', false)
          })
          .catch(e => {
            store.commit('general/SET_LOADER_STATE', false)

            // console.log('error is', e)
          })
      })
    }

    const validate = () => {
      // console.log(currentUser, '-----display name-----', user.value.DisplayName)
      if (currentUser.displayName && (user.value.DisplayName === '' || user.value.DisplayName === null)) {
        db.collection('Users')
          .doc(currentUser.uid)
          .update({ DisplayName: currentUser.displayName })
          .then(() => {
            // console.log('Profile is updated')
          })
      } else if (!currentUser.displayName) {
        dialogOpen.value = true
      }
    }

    // watch updated and if the value changes, call validate
    watch(
      () => user.value,
      () => {
        // console.log('-----user display name-----', newVal)
        validate()
      },
    )

    onMounted(() => {
      // validate()
    })

    return {
      dialogOpen,
      user,
      updated,

      displayName,

      form,
      updateUserProfile,
      validate,

      // validation rules
      required,
    }
  },
  watch: {
    openDialog(newVal) {
      this.dialogOpen = newVal
    },
  },
}
</script>
