<template>
  <AceEditor
    v-model="markdownInput"
    :font-size="14"
    :show-print-margin="false"
    :show-gutter="false"
    :highlight-active-line="true"
    :mode="highlightLang"
    :theme="theme"
    :name="name"
    :editor-props="{$blockScrolling: true}"
    :default-value="dValue"
    :wrap-enabled="true"
    height="100%"
    width="100%"
    :on-change="onChange"
    :on-focus="onFirstClick"
    :read-only="readOnly"
  />
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'

// import brace from 'brace'
import markdownToTxt from 'markdown-to-txt'
import { Ace as AceEditor } from 'vue2-brace-editor'

// global.ace = brace

export default {
  components: {
    AceEditor,
  },
  props: {
    value: String,
    defaultValue: {
      type: String,
      default: 'Code here',
    },
    name: String,

    highlightLang: {
      type: String,
      default: 'javascript',
    },
    theme: {
      type: String,
      default: 'askjarvis',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    let editor

    const input = ref(props.value)
    const rawInput = computed(() => input.value.replace(/```(\w+)?|```*/g, ''))
    const markdownInput = computed(() => markdownToTxt(input.value))

    console.log(input)

    const dValue = ref(props.value !== '' ? props.value : props.defaultValue)

    const onFirstClick = () => {
      editor = global.ace.edit(props.name)
      editor.setValue(props.value)

      editor.commands.addCommand({
        name: 'myCommand',
        bindKey: {
          win: 'Ctrl-G',
          mac: 'Command-G',
        },
        exec(editor) {
          emit('generateCode')
        },
        readOnly: false, // false if this command should not apply in readOnly mode
      })

      // if (props.value === '') {
      //   editor.setValue('')
      // }
    }

    const initEditor = () => {
      require(`brace/mode/${props.highlightLang}`)
      require(`@/assets/theme/${props.theme}.js`)
    }

    // const regex = '^[0-9]*$'
    // const searchResult = () => {
    //   const searchResults = editor.find('[^\x00-\x7F]+ *(?:[^\x00-\x7F]| )*', {
    //     regExp: true,
    //   })

    //   // console.log('search results are', searchResults)
    // }

    onMounted(() => {
      initEditor()
    })

    return {
      markdownInput,
      rawInput,

      editor,
      input,
      dValue,

      initEditor,
      onFirstClick,
    }
  },
  watch: {
    highlightLang() {
      this.initEditor()
    },
    value(newVal) {
      this.input = newVal
    },
  },
  methods: {
    onChange(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>
