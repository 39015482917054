<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <alert></alert>
    <loader></loader>
    <error></error>
    <confirmation-popup></confirmation-popup>
    <onboarding></onboarding>
    <set-display-name :is-persistent="true"></set-display-name>
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <!--<app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->

          <v-select
            :key="`${CurrentWorkspace}_${workspaceDetails.TotalNumber}`"
            v-model="workspace"
            :items="Object.keys(workspaceDetails.Workspaces)"
            outlined
            dense
            hide-details
            class="short"
          >
          </v-select>
          <create-workspace v-if="ability.can('create', 'workspace')"></create-workspace>
          <!-- <upgrade-popup v-else>
            <template #action>
              <v-btn
                color="primary"
                dark
                hide-details
                icon
              >
                <v-icon
                  dark
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <template #title>
              PLEASE UPGRADE TO GET ACCESS TO THIS PAID FEATURE
            </template>
          </upgrade-popup> -->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!-- <app-bar-i18n></app-bar-i18n> -->
          <!-- <v-btn
            v-if="subscription.CurrentPlan === 'Free' || subscription.CurrentPlan === 'A la carte'"
            color="info"
            :to="{name: 'pricing'}"
          >
            Upgrade
          </v-btn> -->
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-notification></app-bar-notification>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <!-- <template #footer>
      <div class="d-flex justify-center">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://askjarvis.io"
          class="text-decoration-none"
        >Bhansali Sharma GbR</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
      </div>
    </template> -->

    <template #v-app-content>
      <!-- <app-customizer class="d-none d-md-block"></app-customizer> -->
      <custom-code class="d-none d-md-block"></custom-code>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'

// App Bar Components
// import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

import { mdiFolderPlusOutline, mdiHeartOutline, mdiMenu, mdiPlus } from '@mdi/js'

import AppBarNotification from '@/components/notifications/AppBarNotifications.vue'
import { getVuetify } from '@core/utils'

import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import navMenuItems from '@/navigation/vertical'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import UpgradePopup from '@/components/popups/UpgradePopup.vue'
import CreateWorkspace from '@/components/workspaces/CreateWorkspace.vue'
import store from '@/store'

import CustomCode from '@/components/code/CustomCode.vue'
import ability from '@/plugins/acl/ability'

import Alert from '@/components/general/Alert.vue'
import Error from '@/components/general/Error.vue'
import Loader from '@/components/general/Loader.vue'
import ConfirmationPopup from '@/components/popups/ConfirmationPopup.vue'
import Onboarding from '@/components/popups/Onboarding.vue'
import SetDisplayName from '@/components/popups/SetDisplayName.vue'

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarUserMenu,
    AppBarThemeSwitcher,
    AppBarNotification,
    CreateWorkspace,
    UpgradePopup,
    CustomCode,
    Onboarding,
    Loader,
    Error,
    Alert,
    ConfirmationPopup,
    SetDisplayName,
  },
  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    const profile = computed(() => store.getters['user/getProfile'])

    const workspaceDetails = computed(() => store.getters['workspaces/getWorkspaces'])
    const CurrentWorkspace = computed(() => store.getters['workspaces/getCurrentWorkspace'])
    const preference = computed(() => store.getters['user/getPreferences'])

    // console.log(preference.value, 'preference before', preference.value.Workspace)
    const workspace = ref(workspaceDetails.value.CurrentWorkspace)
    const updateCurrentWorkspace = () => {
      // console.log('preference', workspaceDetails.value)

      // workspace.value = preference.value.Workspace.default
      if (
        preference.value.Workspace &&
        preference.value.Workspace.default !== '' &&
        preference.value.Workspace.default in workspaceDetails.value.Workspaces
      ) {
        workspace.value = preference.value.Workspace.default
      }
    }

    if (preference.value && preference.value.Workspace) {
      updateCurrentWorkspace()
    }

    const updated = computed(() => store.getters['user/getUpdated'])
    const subscription = computed(() => {
      if (updated.value > 0) {
        return store.getters['user/getSubscription']
      }
    })

    onMounted(() => {
      store.dispatch('app/fetchApplications')
      store.dispatch('app/fetchPlans')

      store.dispatch('user/bindUser')
      store.dispatch('workspaces/bindWorkspaces')
      store.commit('app/SET_CONTENT_HEIGHT', window.innerHeight)
    })

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      profile,
      preference,
      updateCurrentWorkspace,

      workspaceDetails,
      CurrentWorkspace,
      workspace,

      subscription,

      ability,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiFolderPlusOutline,
        mdiPlus,
      },
    }
  },
  watch: {
    workspaceDetails() {
      this.updateCurrentWorkspace()
    },
    preference() {
      // console.log(newVal, 'preference update', newVal.Workspace.default)
      this.updateCurrentWorkspace()
    },
    profile(newVal) {
      // console.log('New profile value in content nav', newVal)
      if (newVal) {
        store.dispatch('workspaces/bindWorkspaces')
        store.dispatch('devices/bindDevices')
      }
      this.updateCurrentWorkspace()
    },
    workspace(newVal) {
      store.commit('workspaces/SET_CURRENT_WORKSPACE', newVal)
      store.commit('codebase/SET_CURRENT_WORKSPACE', newVal)
    },
    CurrentWorkspace(newVal) {
      this.workspace = newVal
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
