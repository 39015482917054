<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    width="500"
  >
    <v-card>
      <v-card-text>
        <span
          v-html="confirmationMsg"
        ></span>
        <!-- text center span element -->
        <!-- justify center the span text -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="saveResponse(false)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="success"
          @click="saveResponse(true)"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

import store from '@/store'

export default {
  setup() {
    const isDialogVisible = ref(false)
    const confirmationMsg = computed(() => store.getters['general/getConfirmationMsg'])

    // watch confirmationMsg and if not '', change isDialogVisible to true
    watch(
      () => confirmationMsg.value,
      (newVal, oldVal) => {
        // console.log(oldVal, 'Confirmation msg', newVal)
        if (newVal !== '') {
          isDialogVisible.value = true
        }
      },
    )

    const saveResponse = val => {
      if (val) {
        store.commit('general/SET_CONFIRMATION_RESPONSE', 'Accepted')
      } else {
        store.commit('general/SET_CONFIRMATION_RESPONSE', 'Rejected')
      }

      isDialogVisible.value = false
    }

    return {
      isDialogVisible,
      confirmationMsg,

      saveResponse,
    }
  },
}
</script>
