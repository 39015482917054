<template>
  <v-row class="px-0">
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="oData.Name"
        label="Full Name"
        outlined
        dense
        placeholder="Full Name"
        hide-details
        :rules="[required]"
        :readonly="readonly"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="oData.Country"
        label="Country"
        :items="countries"
        outlined
        dense
        hide-details
        :rules="[required]"
        :readonly="readonly"
      >
      </v-select>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-text-field
        v-model="oData.Organisation"
        label="Organisation"
        outlined
        dense
        placeholder="Organisation"
        hide-details
        :rules="[required]"
        :readonly="readonly"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="oData.Position"
        label="Role"
        outlined
        :items="roles"
        dense
        hide-details
        :rules="[required]"
        :readonly="readonly"
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="oData.Language"
        :items="listOfLangs"
        outlined
        dense
        label="Preferred Language"
        hide-details
        :rules="[required]"
        :readonly="readonly"
      ></v-select>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-select
        v-model="oData.Source"
        :items="sources"
        outlined
        dense
        label="How did you find out about AskCodi?"
        hide-details
        :rules="[required]"
        :readonly="readonly"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { computed } from '@vue/composition-api'

import countries from '@/assets/data/countries'
import listOfLangs from '@/assets/data/languages'
import roles from '@/assets/data/roles'
import sources from '@/assets/data/sources'

export default {
  props: ['readonly'],
  setup() {
    const oData = computed(() => store.getters['user/getOnboarding'])

    // const onboardingData = computed(() => store.getters['user/getOnboarding'])

    // watch of oData.name has changed, update auth.user.updateProfile{displayName: oData.name}

    return {
      oData,
      required,

      countries,
      listOfLangs,
      roles,
      sources,
    }
  },
}
</script>

<style>
</style>
