<template>
  <v-menu
    v-model="viewMenu"
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
    min-width="400px"
    max-width="700px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-badge
          :content="unseenCount"
          :value="unseenCount"
          color="success"
        >
          <v-icon>
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <!-- {{ sortedNotifications }} -->
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Notifications</span>
              <v-chip
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ unseenCount }} New
              </v-chip>
            </div>
            <v-chip
              v-if="!shouldClear"
              class="primary--text font-weight-semibold ml-1"
              small
              outlined
              @click.stop="clearEverything"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-chip>
            <v-chip
              v-else
              class="v-chip-light-bg primary--text font-weight-semibold ml-1"
              small
              @click.stop="clearEverything"
            >
              Clear
            </v-chip>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <div
            v-for="(notification, index) in sortedNotifications"
            :key="index"
          >
            <component
              :is="`${notification.Type}Notification`"
              :notification="notification"
              @removeNotification="removeNotification(notification)"
            ></component>
            <!-- <text-notification :notification="notification"></text-notification> -->
            <!-- <workspace-action-notification :notification="notification"></workspace-action-notification> -->
            <v-divider :key="index"></v-divider>
          </div>

          <!-- <v-list-item
            key="read-all-btn"
            class="read-all-btn-list-item"
          >
            <v-btn
              block
              color="primary"
            >
              Read All Notifications
            </v-btn>
          </v-list-item> -->
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { getInitialName } from '@core/utils'
import { mdiBellOutline, mdiClose } from '@mdi/js'

// 3rd Party
import { computed, ref, watch } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import PlanActionNotification from './PlanActionNotifications.vue'
import TextNotification from './TextNotification.vue'
import WorkspaceActionNotification from './WorkspaceActionNotification.vue'

import store from '@/store'

import { auth, db } from '@/firebase/config'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,

    TextNotification,
    WorkspaceActionNotification,
    PlanActionNotification,
  },
  setup() {
    const notifications = computed(() => store.getters['user/getNotification'])
    const unseenCount = computed(() => {
      if (notifications.value) return notifications.value.UnseenCount

      return 0
    })

    // sort notifications.value.Notifications array with field CreatedOn
    const sortedNotifications = computed(() => {
      if (notifications.value) {
        return notifications.value.Notifications.sort((a, b) => b.CreatedOn.seconds - a.CreatedOn.seconds)
      }

      return []
    })

    const viewMenu = ref(false)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const shouldClear = ref(false)
    const clearEverything = () => {
      if (shouldClear.value) {
        db.collection(`/Users/${auth.currentUser.uid}/Private/`).doc('Notifications').update({
          Notifications: [],
          UnseenCount: 0,
        })
        shouldClear.value = false
      } else {
        shouldClear.value = true
      }
    }

    const removeNotification = noti => {
      // remove noti from array notifications.value.Notifications

      const index = notifications.value.Notifications.indexOf(noti)
      notifications.value.Notifications.splice(index, 1)
      db.collection(`/Users/${auth.currentUser.uid}/Private/`).doc('Notifications').update({
        Notifications: notifications.value.Notifications,
      })
    }

    // onUnmounted(() => {
    //   // update UnseenCount in db.collection(`/Users/${auth.currentUser.uid}/Private/`).doc('Notifications') to 0
    //   db.collection(`/Users/${auth.currentUser.uid}/Private/`).doc('Notifications').update({
    //     UnseenCount: 0,
    //   })
    // })

    // watch viewMenu, when it changes to false, update unseencount
    watch(
      () => viewMenu.value,
      val => {
        if (!val) {
          db.collection(`/Users/${auth.currentUser.uid}/Private/`).doc('Notifications').update({
            UnseenCount: 0,
          })

          shouldClear.value = false
        }
      },
    )

    return {
      notifications,
      unseenCount,
      sortedNotifications,

      viewMenu,

      getInitialName,
      perfectScrollbarOptions,

      shouldClear,
      clearEverything,

      removeNotification,

      icons: {
        mdiBellOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
