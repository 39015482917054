<template>
  <div id="misc">
    <!-- <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    /> -->

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        Under Maintenance! 🚧
      </h2>
      <p class="text-sm">
        Sorry for the inconvenience but AskCodi is undergoing major updates. We will be back up in 48-72 hours.
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/under-maintenance.png"
        ></v-img>
      </div>

      <p class="text-sm">
        For support, reach out to us via discord or get help from the community and stay up to date with the latest announcements.
      </p>

      <v-btn
        color="primary"
        class="mb-4"
        @click="openDialog"
      >
        Join Discord
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const openDialog = () => {
      window.open('https://discord.gg/3nZmyG4QSA')
    }

    return {
      openDialog,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
