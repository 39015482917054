export default {
  namespaced: true,
  state: {
    currentTab: 0,
    authDetails: {
      deviceId: '',
      ideType: '',
      os: '',
      osarch: '',
    },
  },

  getters: {
    getTab(state) {
      return state.currentTab
    },
    getAuthDetails(state) {
      return state.authDetails
    },
  },
  mutations: {
    SET_TAB(state, payload) {
      state.currentTab = payload
    },
    SET_AUTH_DETAILS(state, payload) {
      state.authDetails = payload
    },
    RESET_ALL(state) {
      state.currentTab = 0
      state.authDetails = {
        deviceId: '',
        ideType: '',
        os: '',
        osarch: '',
      }
    },
    RESET_DEVICE_SETTINGS(state) {
      state.authDetails = {
        deviceId: '',
        ideType: '',
        os: '',
        osarch: '',
      }
    },
  },
  actions: {},
}
