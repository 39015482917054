<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot
          name="action"
        ></slot>
      </div>
    </template>
    <v-card class="text-center text-wrap">
      <v-card-subtitle class="pb-0 pt-5 justify-center">
        <slot name="title"></slot>
      </v-card-subtitle>
      <v-card-title class="ma-0 pa-5">
        <img
          :src="gif"
          width="460"
          height="306"
        >
      </v-card-title>
      <v-card-subtitle>
        <v-img
          :src="$vuetify.theme.dark ? require(`@/assets/images/logo/PoweredBy_200px-Black_HorizText.png`): require(`@/assets/images/logo/PoweredBy_200px-White_HorizText.png`)"
          width="100"
        >
        </v-img>
      </v-card-subtitle>
    </v-card>
  </v-dialog>
</template>
<script>
import { onUnmounted, ref } from '@vue/composition-api'

export default {
  setup(props, { emit }) {
    const dialog = ref(false)
    const searchTerm = ref('upgrade')
    const gif = ref('')
    const apiKey = 'Z5opuc9apYwtWS6FRvy3O0CCn9KB8wzO'
    const searchEndPoint = 'https://api.giphy.com/v1/gifs/random?'
    const url = `${searchEndPoint}&api_key=${apiKey}&tag=${searchTerm.value}&rating=pg`
    fetch(url)
      .then(response => response.json())
      .then(json => {
        // console.log(json.data.id)
        gif.value = `https://media.giphy.com/media/${json.data.id}/giphy.gif`
      })
      .catch(err => console.log(err))

    onUnmounted(() => {
      emit('update')
    })

    return {
      dialog,
      gif,
    }
  },
}
</script>
