<template>
  <v-dialog
    :value="dialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title class="d-flex flex-row justify-center">
        <span class="headline">Onboarding</span>
      </v-card-title>
      <v-form
        ref="form"
        class="px-5 pb-4"
      >
        <v-container>
          <onboarding-data :readonly="false"></onboarding-data>
          <v-row>
            <v-col
              cols="12"
            >
              <v-checkbox
                v-model="MarketingPref"
                hide-details
                dense
                label="Yes, I would like to receive emails from AskCodi with exclusive updates on product news, invitations to events and webinars and resource alerts. I may unsubscribe at my time."
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
            >
              <v-checkbox
                v-model="AcceptPolicy"
                hide-details
                dense
                label="I agree to the Terms & Conditions and Privacy Policy."
                :rules="[requiredPolicy]"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="ma-3 mt-0"
          @click="uploadOnboardingData"
        >
          Get Started
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'

import store from '@/store'

import { auth, db } from '@/firebase/config'

import countries from '@/assets/data/countries'
import listOfLangs from '@/assets/data/languages'
import roles from '@/assets/data/roles'
import sources from '@/assets/data/sources'
import OnboardingData from '../settings/profile/OnboardingData.vue'

export default {
  components: { OnboardingData },
  setup() {
    const form = ref(null)

    const oData = computed(() => store.getters['user/getOnboarding'])

    const user = computed(() => store.getters['user/getUser'])

    const MarketingPref = ref(true)
    const AcceptPolicy = ref(false)

    const dialog = ref(false)

    const requiredPolicy = value => (value ? true : 'Please accept the terms and condition')

    const uploadOnboardingData = () => {
      if (form.value.validate()) {
        oData.value.MarketingPref = MarketingPref.value
        oData.value.AcceptPolicy = AcceptPolicy.value
        oData.value.IsOnboard = true

        db.collection('Users')
          .doc(auth.currentUser.uid)
          .collection('Private')
          .doc('Onboarding')
          .update(oData.value)
          .then(() => {
            // console.log('Document successfully written!')
          })
          .catch(error => {
            oData.value.IsOnboard = false
            console.error('Error writing document: ', error)
          })

        // if (user.value.DisplayName === null) {
        //   db.collection('Users').doc(auth.currentUser.uid).update({
        //     DisplayName: auth.currentUser.displayName,
        //   })
        // }
      }
    }

    return {
      form,
      required,

      oData,
      MarketingPref,
      AcceptPolicy,

      dialog,

      requiredPolicy,

      roles,
      sources,

      countries,
      listOfLangs,

      uploadOnboardingData,
    }
  },
  watch: {
    oData(newVal) {
      // console.log('oData.value', newVal)
      if (newVal) {
        this.dialog = !newVal.IsOnboard

        if (newVal.Language === '') {
          this.oData.Language = 'English'
        }
      }
    },
  },
}
</script>
