import { db } from '@/firebase/config'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    applications: {},
    plans: null,
    contentHeight: 0,
    redirectRoute: '',
  },
  getters: {
    getApplications(state) {
      return state.applications
    },
    getContentHeight(state, getters, rootState) {
      // if (rootState.workspaces.workspaceDetails.CurrentWorkspace === 'Community') {
      //   return state.contentHeight - 58
      // }

      return state.contentHeight
    },
    getPlans(state) {
      return state.plans
    },
    getRedirectRoute(state) {
      console.log('getRedirectRoute', state.redirectRoute)

      return state.redirectRoute
    },
  },
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SET_APPLICATIONS(state, payload) {
      // loop over every document in the payload get the data and append it to applications
      // payload.forEach(doc => {
      //   state.applications[doc.id] = doc.data()
      // })

      state.applications = payload
    },

    // SET_CONTENT_HEIGHT mutation for if rootState.workspaces.workspaceDetails.CurrentWorkspace === 'Community' the set contentHeight = 0
    SET_CONTENT_HEIGHT(state, payload) {
      state.contentHeight = payload - 56 - 64 * 2
    },
    SET_PLANS(state, payload) {
      state.plans = payload
    },
    SET_REDIRECT_ROUTE(state, payload) {
      state.redirectRoute = payload
    },
  },
  actions: {
    fetchApplications({ commit }) {
      db.collection('WebsiteInfo')
        .doc('Applications')
        .get()
        .then(res => {
          commit('SET_APPLICATIONS', res.data())
        })
    },
    fetchPlans({ commit }) {
      db.collection('WebsiteInfo')
        .doc('Plans')
        .get()
        .then(response => {
          commit('SET_PLANS', response.data())
        })
        .catch(error => {
          // console.log(error)
        })
    },
    goToUrl({ state, commit }, payload) {
      // console.log(payload, 'I am done pushing payload to', state.redirectRoute)

      if (state.redirectRoute === '') {
        // import router and go to full path that is in payload
        router.push(payload)
      } else {
        const tempUrl = state.redirectRoute

        // commit('SET_REDIRECT_ROUTE', '')

        router.push(tempUrl)
      }
    },
  },
}
