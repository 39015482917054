import { Ability } from '@casl/ability'
import { computed, ref } from '@vue/composition-api'

import { auth } from '@/firebase/config'
import store from '@/store'

import { allAbilities, pricingAbilities, workspaceAbilities } from '@/assets/data/ability'

const allWorkspaces = computed(() => store.getters['workspaces/getWorkspaces'])
const updated = computed(() => store.getters['user/getUpdated'])
const subscription = computed(() => {
  if (updated.value > 0) {
    return store.getters['user/getSubscription']
  }
})

// console.log('allWorkspaces', allWorkspaces)

const pricingAbility = computed(() => {
  // if subscription.value.CurrentPlan is not equal to 'Free', concat pricingAbility
  if (subscription.value.CurrentPlan !== 'Free') {
    return pricingAbilities
  }

  return []
})

const appAccessAbility = computed(() => {
  if (subscription.value && subscription.value.AppAccess) {
    const access = ref([])
    Object.keys(subscription.value.AppAccess).forEach(item => {
      if (item === '*') {
        access.value.push({ action: 'use', subject: 'all' })
      } else {
        access.value.push({ action: 'use', subject: item })
      }
    })

    return access.value
  }

  return []
})

const workspaceAbility = computed(() => {
  if (allWorkspaces.value.CurrentWorkspace !== 'Community') {
    if (
      allWorkspaces.value.Workspaces[allWorkspaces.value.CurrentWorkspace].Users[auth.currentUser.uid].Role === 'Viewer'
    ) {
      return [
        {
          action: 'leave',
          subject: 'workspace',
        },
        { inverted: true, action: 'use', subject: 'all' },
      ]
    }

    return workspaceAbilities[
      allWorkspaces.value.Workspaces[allWorkspaces.value.CurrentWorkspace].Users[auth.currentUser.uid].Role
    ]
  }

  return workspaceAbilities.Developer
})

const ability = computed(() => {
  const a = new Ability()
  a.update(allAbilities.concat(pricingAbility.value).concat(workspaceAbility.value).concat(appAccessAbility.value))

  return a
})

export default ability
