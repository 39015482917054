<template>
  <v-form
    ref="form"
    class="text-center"
  >
    <v-dialog
      v-model="dialogOpen"
      width="500"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          hide-details
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            dark
          >
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Create workspace
        </v-card-title>
        <v-alert
          color="primary"
          dense
          text
          class="mx-4 caption"
        >
          Created workspace will be private. You can invite members to grant them access to the code.
        </v-alert>

        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-model="name"
            label="Name"
            outlined
            hide-details
            :rules="[required]"
            dense
          ></v-text-field>
          <v-textarea
            v-model="summary"
            label="Summary"
            outlined
            hide-details
            dense
            class="mt-3"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-4">
          <v-btn
            color="primary"
            @click="validate"
          >
            Create workspace
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { required } from '@core/utils/validation'

import { auth, db } from '@/firebase/config'

import store from '@/store'

export default {
  setup() {
    const dialogOpen = ref(false)

    const name = ref('')
    const summary = ref('')

    const { currentUser } = auth

    const form = ref(null)
    const validate = () => {
      if (form.value.validate()) {
        // create a document in Workspaces collection of db with Name as name.value, Summary as summary.value, uid: {Role: 'Author'} in Users
        store.commit('general/SET_LOADER_STATE', true)
        db.collection('Workspaces')
          .add({
            Name: name.value,
            Summary: summary.value,
            Users: {
              [currentUser.uid]: {
                Email: currentUser.email,
                Role: 'Author',
                Status: true,
              },
            },
          })
          .then(doc => {
            // get id from doc
            // console.log('doc that was created is', doc.id)
            store.commit('user/ADD_TO_WORKSPACE', doc.id)
            store.commit('general/SET_SNACKBAR_MSG_STATE', `Your workspace ${name.value} is created`)
            store.commit('general/SET_LOADER_STATE', false)

            dialogOpen.value = false

            // add doc.id to an array of strings
            // and then update db.collection(`/Users/${uid}/Private/`).doc('Profile')
            // db.collection(`/Users/${currentUser.uid}/Private/`)
            //   .doc('Profile')
            //   .update({
            //     Workspaces: firebase.firestore.FieldValue.arrayUnion(doc.id),
            //   })
            //   .then(() => {
            //     // console.log('Workspace added to Profile')
            //   })
            //   .catch(error => {
            //     // console.log('Error adding workspace to Profile', error)
            //   })
          })
          .catch(error => {
            console.error('Error adding document: ', error)
            store.commit('general/SET_LOADER_STATE', false)
            dialogOpen.value = false
          })
      }
    }

    return {
      dialogOpen,

      summary,
      name,

      form,
      validate,

      // validation rules
      required,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style>
</style>
