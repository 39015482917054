import 'dotenv/config'
import { Client } from 'typesense'

export default function getClient(TSApiKey) {
  // console.log('I am in getClient', TSApiKey)

  return new Client({
    nodes: [
      {
        host: 'tph53wf0281av9mgp-1.a1.typesense.net',
        port: 443,
        protocol: 'https',
      },
    ],
    apiKey: TSApiKey,
    connectionTimeoutSeconds: 2,
  })
}
