<template>
  <div>
    <alert></alert>
    <loader></loader>
    <error></error>
    <confirmation-popup></confirmation-popup>
    <layout-blank>
      <slot></slot>
    </layout-blank>
  </div>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'

import Alert from '@/components/general/Alert.vue'
import Error from '@/components/general/Error.vue'
import Loader from '@/components/general/Loader.vue'
import ConfirmationPopup from '@/components/popups/ConfirmationPopup.vue'

export default {
  components: {
    LayoutBlank,
    Loader,
    Error,
    Alert,
    ConfirmationPopup,
  },
}
</script>

<style>
</style>
