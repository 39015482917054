import { auth, db, functions, timestamp } from '@/firebase/config'
import useGlobalVariables from '@/globalVar'
import { firestoreAction } from 'vuexfire'

const { dbCol } = useGlobalVariables()

const defaultDb = {
  User: {
    Email: '',
    ProfilePhoto: '',
    DisplayName: '',
  },
  Onboarding: {
    AcceptPolicy: true,
    Country: '',
    MarketingPref: true,
    Name: '',
    Organisation: '',
    Position: '',
    Source: '',
    IsOnboard: true,
    Language: '',
  },
  Preferences: {},
  Profile: {
    AddOnCredits: 0,

    CreatedOn: '',

    CurrentUsage: {
      Applications: {},
      Total: 0,
    },
    UpdatedOn: '',
    Workspaces: [],
  },
  Notification: {
    UnseenCount: 0,
    Notifications: [],
  },
  Statistics: {},
  Subscription: {
    Members: [],
    RemainingMembers: 0,
    CustomerId: '',
    AppAccess: {},
    CurrentPlan: '',
  },
}

export default {
  namespaced: true,
  state: {
    ...JSON.parse(JSON.stringify(defaultDb)),
    updated: 0,
  },
  getters: {
    getUser(state) {
      return state.User
    },
    getOnboarding(state) {
      return state.Onboarding
    },
    getPreferences(state) {
      return state.Preferences
    },
    getProfile(state) {
      return state.Profile
    },
    getNotification(state) {
      return state.Notification
    },
    getStatistics(state) {
      return state.Statistics
    },
    getSubscription(state) {
      return state.Subscription
    },
    getUpdated(state) {
      return state.updated
    },
  },
  mutations: {
    ADD_TO_WORKSPACE(state, payload) {
      state.Profile.Workspaces.push(payload)
    },

    SET_UPDATED(state) {
      // console.log('SET updated', state)
      state.updated += 1
    },

    RESET_ALL(state) {
      state.User = defaultDb.User
      state.Onboarding = defaultDb.Onboarding
      state.Preferences = defaultDb.Preferences
      state.Profile = defaultDb.Profile
      state.Notification = defaultDb.Notification
      state.Statistics = defaultDb.Statistics
      state.Subscription = defaultDb.Subscription
      state.updated = 0
    },
  },
  actions: {
    bindUser: firestoreAction(({ commit, bindFirestoreRef, dispatch }) => {
      const userID = auth.currentUser.uid

      // console.log(userID)

      bindFirestoreRef('User', db.collection(dbCol.users).doc(userID)).then(() => {
        // console.log('User is updated', res)
        commit('SET_UPDATED')
      })
      bindFirestoreRef('Onboarding', db.collection(`/Users/${userID}/Private/`).doc('Onboarding'))
      bindFirestoreRef('Profile', db.collection(`/Users/${userID}/Private/`).doc('Profile'))
      bindFirestoreRef('Statistics', db.collection(`/Users/${userID}/Private/`).doc('Statistics'))
      bindFirestoreRef('Preferences', db.collection(`/Users/${userID}/Private/`).doc('Preferences'))
      bindFirestoreRef('Notification', db.collection(`/Users/${userID}/Private/`).doc('Notifications'))
      bindFirestoreRef('Subscription', db.collection(`/Users/${userID}/Private/`).doc('Subscription')).then(() => {
        // console.log('User is updated', res)
        commit('SET_UPDATED')
      })

      // when any bindFirestoreRef gets triggered, commit SET_UPDATED
    }),
    unbindUser: firestoreAction(({ unbindFirestoreRef, commit }) => {
      unbindFirestoreRef('User')
      unbindFirestoreRef('Onboarding')
      unbindFirestoreRef('Profile')
      unbindFirestoreRef('Statistics')
      unbindFirestoreRef('Preferences')
      unbindFirestoreRef('Notification')
      unbindFirestoreRef('Subscription')

      commit('RESET_ALL')
    }),
    checkMonthly: ({ state }) => {
      // console.log(state.Statistics, 'currentUsage---currentLimit')
      if (timestamp.now().seconds - state.Statistics.UpdatedOn.seconds >= 2628288) {
        // call resetUsage
        const resetUsage = functions.httpsCallable('resetStats')
        const result = resetUsage({
          userid: auth.currentUser.uid,
        })

        result.then(() => {
          // console.log('reset usage')
        })
      }
    },
    checkLimit: ({ state, dispatch }) => {
      dispatch('checkMonthly')

      // const currentAppUsage = computed(() => statistics.value.CurrentUsage.Applications[appId])
      const currentUsage = state.Statistics.CurrentUsage.TotalUsage

      // const currentAppLimit = computed(() => profile.value.AppAccess[appId].Limit)
      const currentLimit = state.Subscription.MonthlyLimit

      // console.log(currentUsage, 'currentUsage---currentLimit', currentLimit)

      // try catch inside computed of todaysUsage
      if (currentUsage.value < currentLimit.value) {
        return true
      }
      if (state.Subscription.AddOnCredits > 0) {
        return true
      }

      return false
    },
  },
}
