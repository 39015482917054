import { ref } from '@vue/composition-api'
import { stemmer } from 'stemmer'
import { firestoreAction } from 'vuexfire'

import { auth, db } from '@/firebase/config'

import getClient from '@/composables/typesenseClient'

const customSerializer = doc => {
  const data = doc

  // adds _doc to be used to paginate
  Object.defineProperty(data, '_doc', {
    value: doc,
  })

  // adds id as enumerable attribute so we can easily access it
  Object.defineProperty(data, 'id', {
    value: doc.id,
    enumerable: true,
  })

  return data
}

const appendString = (search, filter) => {
  if (search === '') {
    return filter
  }

  return `${search} && ${filter}`
}

export default {
  namespaced: true,
  state: {
    allCodes: [],
    codesBatch: [],
    loadMoreLastCode: null,
    moreCodesPending: true,
    lastGen: {},
    page: 1,
    search: '',
    codebaseInfoDict: {
      currentWorkspace: 'Community',
      limit: 1,
      isFav: 0,
      lang: '',
      key: '',
      ownership: '',
    },
  },
  getters: {
    allCodes: state => state.allCodes,
    moreCodes: state => state.moreCodesPending,
    getLastGen: state => state.lastGen,
    getCodebaseInfoDict: state => state.codebaseInfoDict,
    getSearch: state => state.search,
  },
  mutations: {
    SET_SEARCH(state, payload) {
      state.search = payload
    },
    MERGE_CODES_BATCH(state, payload) {
      // console.log(`Adding ${payload.codes.length} to the list`)
      state.allCodes = state.allCodes.concat(payload.codes)

      state.page += 1
    },
    SET_LOADMORE_LAST(state) {
      // console.log('Setting last...')
      // console.log(state.allCodes.length)
      // eslint-disable-next-line no-underscore-dangle
      state.loadMoreLastCode = state.allCodes[state.allCodes.length - 1]._doc
      state.codesBatch = []

      // state.codebaseInfoDict.page += 1
    },
    ALL_CODES_LOADED(state) {
      state.moreCodesPending = false
    },
    RESET_ALL(state) {
      state.allCodes = []
      state.codesBatch = []
      state.loadMoreLastCode = null
      state.moreCodesPending = true

      state.page = 1

      // state.codebaseInfoDict.isFav = 0
      // state.codebaseInfoDict.search = ''
      // state.codebaseInfoDict.lang = ''
      // state.codebaseInfoDict.key = ''
      // state.codebaseInfoDict.ownership = ''
    },
    SET_INITIAL(state, payload) {
      state.lastGen = payload

      // console.log('last generated is ', payload)
    },
    SET_CURRENT_WORKSPACE(state, payload) {
      state.codebaseInfoDict.currentWorkspace = payload

      // console.log('last generated is ', payload)
    },
    SET_NEXT_PAGE(state) {
      state.codebaseInfoDict.page += 1
    },
  },
  actions: {
    getTypesenseCode: ({ rootState, state, commit }) => {
      const searchParams = ref({
        q: state.search === '' ? '*' : state.search,
        query_by: 'Query,Info,Output',
        page: state.page,
        per_page: 5,
        filter_by: '',
        sort_by: 'CreatedOn:desc',
      })

      if (state.codebaseInfoDict.key && state.codebaseInfoDict.key !== '') {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        searchParams.value.filter_by = appendString(searchParams.value.filter_by, `App:${state.codebaseInfoDict.key}`)
      }

      if (state.codebaseInfoDict.lang && state.codebaseInfoDict.lang !== '') {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)

        // create a query where (payload.lang is in array of FromLanguage) || (payload.lang == ToLanguage)
        searchParams.value.filter_by = appendString(
          searchParams.value.filter_by,
          `FromLanguage:[${state.codebaseInfoDict.lang}]`,
        )
      }

      if (state.codebaseInfoDict.isFav) {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        searchParams.value.filter_by = appendString(
          searchParams.value.filter_by,
          `IsFavorite:${!!state.codebaseInfoDict.isFav}`,
        )
      }

      // if (state.codebaseInfoDict.ownership === 'Created by me') {
      searchParams.value.filter_by = appendString(searchParams.value.filter_by, `UserId:${auth.currentUser.uid}`)

      // }

      // console.log(
      //   rootState.workspaces.workspaceDetails.Workspaces[rootState.workspaces.workspaceDetails.CurrentWorkspace],
      //   'rootState.workspaces.workspaceDetails.Workspaces[rootState.workspaces.workspaceDetails.CurrentWorkspace].TSApiKey,',
      // )

      const client = getClient(
        rootState.workspaces.workspaceDetails.Workspaces[rootState.workspaces.workspaceDetails.CurrentWorkspace]
          .TSApiKey,
      )

      client
        .collections('Codebase')
        .documents()
        .search(searchParams.value)
        .then(results => {
          // console.log('server response:', results)
          const codes = results.hits.map(doc => doc.document)

          // console.log(`Got ${results.found} codes`)
          if (results.found > 0) {
            commit('MERGE_CODES_BATCH', {
              codes,
            })

            // commit('SET_LOADMORE_LAST')

            // state.codebaseInfoDict.page += 1
          }

          // set all loaded if we dont return as many as the limit
          if (results.hits.length < 5) commit('ALL_CODES_LOADED')
        })
        .catch(error => {
          // console.log(error)
        })
    },
    getCodeUniversal: firestoreAction(({ state, rootState, commit }) => {
      const payload = state.codebaseInfoDict
      const codeCollection = db.collection('Codebase')

      let query = codeCollection

      if (payload.key && payload.key !== '') {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        query = query.where('App', '==', payload.key)
      }

      if (payload.lang && payload.lang !== '') {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        query = query.where('FromLanguage', 'array-contains', payload.lang)

        // create a query where (payload.lang is in array of FromLanguage) || (payload.lang == ToLanguage)
      }

      if (payload.isFav) {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        query = query.where('IsFavorite', '==', true)
      }

      if (payload.ownership === 'Created by me') {
        query = query.where('UserId', '==', auth.currentUser.uid)
      }

      if (payload.search && payload.search !== '') {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        const searchWords = payload.search.split(' ')

        // stem the searchWords
        const stemmedSearchWords = searchWords.map(word => stemmer(word))

        // We're passing in the stemmedSearchWords array, joined by the pipe character (|).
        // We're passing in the 'i' flag, which makes the regular expression case-insensitive.
        // const searchWordsRegex = new RegExp(stemmedSearchWords.join('|'), 'i')
        // console.log(searchWordsRegex)

        // query = query.where('keywords', 'array-contains', searchWordsRegex)

        query = query.where('keywords', 'array-contains-any', stemmedSearchWords)
      }

      query = query.orderBy('CreatedOn', 'desc')

      if (state.loadMoreLastCode) {
        // console.log('Loading Codes batch. Last item is', state.loadMoreLastCode.id)
        query = query.startAfter(state.loadMoreLastCode || null)
      }

      return query
        .limit(payload.limit || 3)
        .get()
        .then(snapshot => {
          const codes = snapshot.docs.map(doc => customSerializer(doc))

          // console.log(`Got ${codes.length} codes`)
          if (codes.length > 0) {
            commit('MERGE_CODES_BATCH', {
              codes,
            })
            commit('SET_LOADMORE_LAST')
          }

          // set all loaded if we dont return as many as the limit
          if (codes.length < payload.limit) commit('ALL_CODES_LOADED')
        })
    }),
  },
}
