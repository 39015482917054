<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card class="text-center text-wrap">
      <v-card-subtitle class="pb-0 pt-5 justify-center">
        <span v-html="errorMsg">

        </span>
        <!-- {{ errorMsg }} -->
      </v-card-subtitle>
      <v-card-title class="ma-0 pa-5">
        <img
          :src="gif"
          width="460"
          height="306"
        >
      </v-card-title>
      <v-card-subtitle>
        <v-img
          :src="$vuetify.theme.dark ? require(`@/assets/images/logo/PoweredBy_200px-Black_HorizText.png`): require(`@/assets/images/logo/PoweredBy_200px-White_HorizText.png`)"
          width="100"
        >
        </v-img>
      </v-card-subtitle>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref } from '@vue/composition-api'

import store from '@/store'

export default {
  setup() {
    const dialog = ref(false)

    const errorMsg = computed(() => store.getters['general/getError'])
    const errorType = computed(() => store.getters['general/getErrorType'])

    const gif = ref('')
    const apiKey = 'Z5opuc9apYwtWS6FRvy3O0CCn9KB8wzO'
    const searchEndPoint = 'https://api.giphy.com/v1/gifs/random?'
    const url = computed(() => `${searchEndPoint}&api_key=${apiKey}&tag=${errorType.value}&rating=pg`)
    fetch(url.value)
      .then(response => response.json())
      .then(json => {
        // console.log(json.data.id)
        gif.value = `https://media.giphy.com/media/${json.data.id}/giphy.gif`
      })
      .catch(err => console.log(err))

    return {
      dialog,
      errorMsg,
      gif,
    }
  },
  watch: {
    errorMsg(newVal) {
      // console.log('errorMsg', newVal)
      if (newVal !== '') {
        this.dialog = true
      }
    },
    dialog(newVal) {
      if (!newVal) {
        store.commit('general/SET_ERROR_MSG', '')
      }
    },
  },
}
</script>
