<template>
  <v-snackbar
    v-model="isSnackbarVisible"
    color="success"
  >
    {{ snarkbarMsg }}
  </v-snackbar>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

import store from '@/store'

export default {
  setup() {
    const snarkbarMsg = computed(() => store.getters['general/getSnackbarMsg'])
    const isSnackbarVisible = ref(false)

    // watch snarkbarMsg and then change isSnackbarVisible = true
    watch(
      () => snarkbarMsg.value,
      () => {
        isSnackbarVisible.value = true
      },
    )

    return {
      isSnackbarVisible,
      snarkbarMsg,
    }
  },
}
</script>

<style>
</style>
