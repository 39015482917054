export default function useGlobalVariables() {
  const dbCol = {
    websiteInfo: 'WebsiteInfo',
    applications: 'Applications',
    plans: 'Plans',
    announcements: 'Announcements',

    userChecklist: 'userChecklist',

    appSumo: 'appSumo',
    appSumoInvited: 'appSumoInvited',

    codeBase: 'codeBase',
    privateCodeBase: 'privateCodeBase',
    teamCodeBase: 'teamCodeBase',

    users: 'Users',
    onboarding: 'onboarding',
    team: 'team',

    requestedTeam: 'requestedTeam',
    userPreference: 'preference',
  }

  const languages = {
    'Angular JS': 'javascript',
    Batch: 'batchfile',
    Bootstrap: 'css',
    CSS: 'css',
    C: 'c_cpp',
    'C#': 'csharp',
    'C++': 'c_cpp',
    Dart: 'dart',
    Django: 'python',
    'Ethers.js': 'javascript',
    Excel: 'text',
    Flutter: 'dart',
    Haskell: 'haskell',
    HTML: 'html',
    JScript: 'text',
    'Java 11': 'java',
    'Java 8': 'java',
    Jquery: 'javascript',
    Kotlin: 'kotlin',
    LUA: 'lua',
    Laravel: 'php',
    Mongo: 'text',
    MySQL: 'sql',
    'Node JS': 'javascript',
    'Next JS': 'javascript',
    Perl: 'perl',
    PHP: 'php',
    PowerPoint: 'text',
    PowerShell: 'powershell',
    PostgreSQL: 'sql',
    'Python 2': 'python',
    'Python 3': 'python',
    'R Language': 'r',
    'React Class': 'jsx',
    'React Functional': 'jsx',
    'React Native': 'jsx',
    Regex: 'text',
    Ruby: 'ruby',
    'Ruby on Rails': 'ruby',
    Rust: 'rust',
    SCSS: 'scss',
    'Tailwind CSS': 'css',
    SQL: 'sql',
    Shell: 'text',
    'Solidity ^0.4': 'text',
    'Solidity ^0.8': 'text',
    'Svelte JS': 'svelte',
    Swift: 'swift',
    TypeScript: 'javascript',
    'Vue 3': 'javascript',
    'Vanilla JavaScript': 'javascript',
    'Web3.js': 'javascript',
    VBScript: 'vbscript',
    Word: 'text',
    'T-SQL': 'sql',
  }

  const defaultPayload = {
    limit: 4,
    isFav: false,
    search: '',
    lang: '',
    key: '',
    ownership: '',
  }

  const sqlType = [
    'CHAR',
    'VARCHAR',
    'BIT',
    'BINARY',
    'BLOB',
    'TEXT',
    'ENUM',
    'SET',
    'TINYBLOB',
    'TINYTEXT',
    'MEDIUMBLOB',
    'MEDIUMTEXT',
    'LONGBLOB',
    'LONGTEXT',
    'DATE',
    'TIME',
    'DATETIME',
    'TIMESTAMP',
    'YEAR',
    'INT',
    'INTEGER',
    'TINYINT',
    'SMALLINT',
    'MEDIUMINT',
    'BIGINT',
    'FLOAT',
    'DOUBLE',
    'DECIMAL',
    'NUMERIC',
  ]

  return {
    dbCol,
    languages,
    defaultPayload,
    sqlType,
  }
}
