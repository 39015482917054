import { mdiCheckboxBlankCircleOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

const themeConfig = computed(() => {
  // get website base url
  const baseUrl = window.location.origin

  const defaultTheme = ref({
    app: {
      name: require('@/assets/images/logo/askcodi_textonly.png'),

      // logo: require('@/assets/clients/tm/TMINC.png'),

      logo: require('@/assets/images/logo/askcodi.svg'),
      authLogo: require('@/assets/images/logo/askcodi_text.png'),
      isDark: false,
      isRtl: false,
      contentLayoutNav: 'vertical', // vertical, horizontal
      routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
      // ! `semi-dark` isn't available for horizontal nav menu
      skinVariant: 'default', // default, bordered, semi-dark
      contentWidth: 'boxed',
    },
    menu: {
      isMenuHidden: false,
      isVerticalNavMini: false,
      verticalMenuAccordion: true,
      groupChildIcon: mdiCheckboxBlankCircleOutline,
      horizontalNavMenuGroupOpenOnHover: true,
    },
    appBar: {
      /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
      type: 'fixed', // fixed, static, hidden
      isBlurred: true,
    },
    footer: {
      type: 'static', // fixed, static, hidden
    },
    themes: {
      light: {
        // primary: '#9155FD',
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  })

  if (baseUrl === 'https://tm.askcodi.com') {
    defaultTheme.value.app.name = require('@/assets/clients/tm/Thinking Minds.svg')
    defaultTheme.value.app.logo = require('@/assets/clients/tm/TMINC.png')
    defaultTheme.value.app.authLogo = require('@/assets/clients/tm/TMINC_Clear_Logo.png')
  }

  return defaultTheme.value
})

export default themeConfig.value
