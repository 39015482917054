import { mdiApplication, mdiChatOutline, mdiHeartMultiple, mdiHomeOutline, mdiXml } from '@mdi/js'
import { computed } from '@vue/composition-api'
import { map, pickBy } from 'lodash'

import store from '@/store'

const appDict = computed(() => store.getters['app/getApplications'])
const preference = computed(() => store.getters['user/getPreferences'])

// get elements from dict preference where value.isFavorite === true using lodash
const favoriteApps = computed(() => {
  const apps = appDict.value
  const pref = preference.value
  const favorites = pickBy(pref, (value, key) => value.IsFavorite)

  if (apps.autocomplete) {
    // console.log(apps, '-------------', favorites)

    return map(favorites, (value, key) => ({
      title: apps[key].name,
      to: {
        name: 'application',
        params: { id: key },
      },
    }))
  }
})

const nav = computed(() => [
  {
    subheader: '.',
  },
  {
    title: 'Chat',
    icon: mdiChatOutline,
    to: 'chat',
  },
  {
    title: 'Notebook',
    icon: mdiChatOutline,
    to: 'notebooks',
  },
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },

  // {
  //   title: 'Library',
  //   icon: mdiLibraryShelves,
  //   to: 'library',
  // },
  {
    subheader: 'Application',
  },
  {
    title: 'Applications',
    icon: mdiApplication,
    to: 'applications',
  },
  {
    title: 'Favourites',
    icon: mdiHeartMultiple,
    children: favoriteApps.value,
  },
  {
    subheader: '.',
  },
  {
    title: 'Codebase',
    icon: mdiXml,
    to: 'codebase',
  },

  // {
  //   subheader: 'Additional',
  // },
  // {
  //   title: 'Pricing',
  //   icon: mdiFileOutline,
  //   to: 'pricing',
  // },
  // {
  //   title: 'Settings',
  //   icon: mdiFileOutline,
  //   to: 'settings',
  // },
])

export default nav
