const defaultCurrentCode = {
  input: '',
  output: '',
  fromLang: [],
  toLang: '',
  context: '',
  app: '',
}

export default {
  namespaced: true,
  state: {
    app: '',
    currentCode: {
      input: '',
      output: '',
      fromLang: ['', ''],
      toLang: '',
      context: '',
      info: '',
      generated: '',
    },
    feedback: false,
    updateScore: 0,
    canContinue: false,
  },
  getters: {
    getCurrentCode(state) {
      return state.currentCode
    },
    getCurrentApp(state) {
      return state.app
    },
    getCurrentContext(state) {
      return state.currentCode.context
    },
    getCurrentScore(state) {
      return state.updateScore
    },
    getFeedback(state) {
      return state.feedback
    },
    getCanContinue(state) {
      return state.canContinue
    },
  },
  mutations: {
    SET_CURRENT_CODE(state, payload) {
      // copy the values of same fields from payload whichever exists to state.currentCode
      // console.log('SET_CURRENT_CODE', payload)
      Object.entries(JSON.parse(JSON.stringify(payload))).forEach(([key, val]) => {
        // console.log('key', key, 'val', val)

        // copy value of array in val to state.currentCode[key]
        state.currentCode[key] = val

        // console.log(state.currentCode[key])
      })

      // const { input, output, fromLang, toLang, context } = payload
      // state.currentCode = {
      //   input: input || state.currentCode.input,
      //   output: output || state.currentCode.output,
      //   fromLang: fromLang || state.currentCode.fromLang,
      //   toLang: toLang || state.currentCode.toLang,
      //   context: context || state.currentCode.context,
      // }
    },
    UPDATE_INPUT(state, payload) {
      state.currentCode.input = payload
    },
    UPDATE_OUTPUT(state, payload) {
      state.currentCode.output = payload
    },
    UPDATE_FROMLANG(state, payload) {
      state.currentCode.fromLang = [...payload]
    },
    UPDATE_TOLANG(state, payload) {
      // console.log('I am triggered', payload)
      state.currentCode.toLang = payload
    },
    UPDATE_CONTEXT(state, payload) {
      state.currentCode.context = payload
    },
    UPDATE_INFO(state, payload) {
      state.currentCode.info = payload
    },
    UPDATE_GENERATED(state, payload) {
      state.currentCode.generated = payload
    },
    UPDATE_CURRENT_SCORE(state) {
      state.updateScore += 1
    },
    UPDATE_APP(state, payload) {
      state.app = payload
    },
    UPDATE_FEEDBACK(state, payload) {
      state.feedback = payload
    },

    UPDATE_CAN_CONTINUE(state, payload) {
      state.canContinue = payload
    },

    RESET_FOR_APPLICATION(state, payload) {
      state.app = payload
      state.feedback = false
      state.canContinue = false

      state.currentCode = {
        input: '',
        output: '',
        fromLang: ['', ''],
        toLang: '',
        context: '',
        info: '',
        generated: '',
      }

      //   state.currentCode.toLaå
    },

    RESET_ALL(state) {
      state.currentCode = defaultCurrentCode
      state.updateScore = 0
    },
  },
  actions: {},
}
