<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <lottie
            :options="{animationData:require('@/assets/lotties/44-avatar-user-in-circle-flat-edited.json')}"
          />
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ currentUser.displayName }}
          </span>
          <small class="text--disabled text-wrap">{{ currentUser.email }}</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <!-- Settings -->
      <v-list-item to="settings">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Pricing -->
      <!-- <v-list-item to="pricing">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCurrencyUsd }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- FAQ -->
      <v-list-item href="https://www.askcodi.com/faq">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useRouter } from '@core/utils'

import {
  mdiAccountOutline,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import Lottie from 'vue-lottie'

import { auth } from '@/firebase/config'
import store from '@/store'

export default {
  components: {
    Lottie,
  },
  setup() {
    const { router } = useRouter()
    const { currentUser } = auth

    const logout = () => {
      auth
        .signOut()
        .then(() => {
          store.commit('general/SET_SNACKBAR_MSG_STATE', 'You are signed out.')
          store.dispatch('user/unbindUser')

          router.push('login')
        })
        .catch(error => {
          store.commit('general/SET_ERROR_MSG', error.message)
        })
    }

    return {
      logout,
      currentUser,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
