import { auth, db, functions } from '@/firebase/config'

export default {
  namespaced: true,
  state: {
    status: 'idle',
    error: '',
    notebook: { Messages: [] },
  },
  getters: {
    getState(state) {
      return { notebook: state.notebook, status: state.status, error: state.error }
    },
  },
  mutations: {
    SET_NOTEBOOK(state, payload) {
      state.notebook = payload
    },
    ADD_NEW_CELL(state, payload) {
      state.notebook.Messages.push({ role: 'user', content: payload })
    },
    SET_MESSAGES(state, payload) {
      state.notebook.Messages = payload
    },
    DELETE_NOTEBOOK(state) {
      state.notebook({ Messages: [] })
    },
  },
  actions: {
    bindNotebook: ({ commit }, notebookId) => {
      // bind new ref to notebook with given id
      console.log('notebook to fetdh', notebookId)
      const noteDoc = db.collection('NotebookContent').doc(notebookId)

      // get the document from noteDoc
      noteDoc
        .get()
        .then(doc => {
          if (doc.exists) {
            // commit the document using SET_NOTEBOOK mutation
            commit('SET_NOTEBOOK', {
              id: doc.id,
              ...doc.data(),
            })
          } else {
            console.log('No such document!')
          }
        })
        .catch(error => {
          console.log('Error getting document:', error)
        })
    },

    // action to create a new notebook in firestore
    createNotebook: async ({ commit }, notebookId) => {
      console.log('Notebook to be created', notebookId)

      // create a new doc with notebookId and data of messages: []

      const doc = await db.collection('NotebookContent').doc(notebookId).set({
        Messages: [],
        Owner: auth.currentUser.uid,
      })

      if (doc) {
        console.log('Notebook created successfully!', doc)
        commit('SET_NOTEBOOK', {
          id: doc.id,
          Messages: [],
          Owner: auth.currentUser.uid,
        })

        return doc.id
      }
    },

    addNewCell: ({ state, commit }, message) => {
      console.log('messages in addNewCell', state)
      db.collection('NotebookContent')
        .doc(state.notebook.id)
        .update({
          Messages: [...state.notebook.Messages, { role: 'user', content: message }],
        })

      commit('ADD_NEW_CELL', message)
    },

    deleteCell: async ({ state, commit }, index) => {
      const notebookRef = db.collection('NotebookContent').doc(state.notebook.id)
      const messages = state.notebook.Messages

      // Remove the message from the array in state
      messages.splice(index, 1)
      commit('SET_MESSAGES', messages)

      // Update the NotebookContent document in the database
      await notebookRef.update({
        Messages: messages,
      })
    },

    generateResponse: ({ rootState, state, commit }, { act, index, msg, lang }) => {
      console.log('rootState', act, index, msg, lang)

      const startIndex = Math.max(0, index - 5)
      const endIndex = Math.min(index + 5, state.notebook.Messages.length)
      const messagesSubset = state.notebook.Messages.slice(startIndex, endIndex)

      messagesSubset.push({ role: 'user', content: msg })

      const askCodi = functions.httpsCallable('askCodi')
      askCodi({
        app: 'backend',
        language: 'Python 3',
        messages: messagesSubset,
      })
        .then(code => {
          // add code.data as a message in notebook.Messages and in db, it needs to be pushed as { role: 'system', content: code.data }
          console.log('code generated', code.data.message.content)
          const message = { role: 'system', content: code.data.message.content }
          commit('ADD_NEW_CELL', message.content) // add message to notebook.Messages
          db.collection('NotebookContent')
            .doc(state.notebook.id)
            .update({
              Messages: [...state.notebook.Messages, message],
            })
        })
        .catch(error => {
          console.log('error is', error)
        })
    },

    // action to delete a notebook from firestore
    deleteNotebook: ({ commit }, notebookId) => {
      // delete notebook from firestore
      db.collection('NotebookContent').doc(notebookId).delete()
      commit('DELETE_NOTEBOOK')
    },
  },
}
