<template>
  <div>
    <component
      :is="resolveLayoutVariant"
      :class="`skin-variant--${appSkinVariant}`"
    >
      <transition
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <!-- <under-maintanence></under-maintanence> -->
        <router-view :key="$route.path"></router-view>
      </transition>
    </component>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { getVuetify, useRouter } from '@core/utils'

import useDynamicVh from '@core/utils/useDynamicVh'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import themeConfig from '@themeConfig'

// import { db } from '@/firebase/config'
import store from '@/store'
import UnderMaintanence from './views/UnderMaintanence.vue'

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    UnderMaintanence,
  },
  setup() {
    const $vuetify = getVuetify()
    const { route } = useRouter()

    const { appContentLayoutNav, appSkinVariant, appRouteTransition, menuIsVerticalNavMini } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    // if ($vuetify.breakpoint.lgAndUp) {
    //   menuIsVerticalNavMini.value = $vuetify.breakpoint.lgAndUp
    // }

    onMounted(() => {
      // store.dispatch('app/fetchApplications')
      // store.dispatch('app/fetchPlans')

      // store.dispatch('user/bindUser')
      // store.dispatch('workspaces/bindWorkspaces')
      store.commit('app/SET_CONTENT_HEIGHT', window.innerHeight)
      const favicon = document.getElementById('favicon')
      favicon.href = themeConfig.app.logo
    })

    window.addEventListener('resize', () => {
      //   windowHeight.value = window.innerHeight
      store.commit('app/SET_CONTENT_HEIGHT', window.innerHeight)
    })

    return {
      route,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      menuIsVerticalNavMini,

      $vuetify,
    }
  },
  watch: {
    'window.innerHeight': function (newVal) {
      store.commit('app/SET_CONTENT_HEIGHT', newVal)
    },

    '$vuetify.breakpoint.lgAndUp': function (newVal) {
      // console.log('lgAndUp', newVal)
      store.commit('appConfig/TOGGLE_MENU_VERTICAL_NAV_MINI', newVal)
    },

    route(newVal) {
      // console.log('route', newVal)
      if (this.$vuetify.breakpoint.lgAndUp) {
        // if (newVal.name === 'home' || newVal.name === 'applications') {
        //   store.commit('appConfig/TOGGLE_MENU_VERTICAL_NAV_MINI', false)
        // } else {
        //   store.commit('appConfig/TOGGLE_MENU_VERTICAL_NAV_MINI', true)
        // }
      }
    },
  },
}
</script>
