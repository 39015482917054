/* eslint-disable no-unused-vars */
// import from libs folder after copy

import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'

// import Highlight from 'vue-markdown-highlight'
import VMdPreview from '@kangc/v-md-editor/lib/preview'

import githubTheme from '@kangc/v-md-editor/lib/theme/github'

import hljs from 'highlight.js'
import VueMeta from 'vue-meta'
import { firestorePlugin } from 'vuefire'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import { auth } from '@/firebase/config'

import '@/assets/theme/github.css'
import '@kangc/v-md-editor/lib/style/preview.css'

// import '@kangc/v-md-editor/lib/theme/style/github-theme.css'
import 'highlight.js/styles/dark.css'
import 'highlight.js/styles/github.css'

// highlightjs

VMdPreview.use(githubTheme, {
  Hljs: hljs,
})

Vue.use(VMdPreview)

Vue.use(firestorePlugin)
Vue.use(VueMeta)

Vue.config.productionTip = false
let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount('#app')
  }
})
