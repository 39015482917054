<template>
  <v-dialog
    v-model="isLoaderVisible"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="pt-3">
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed } from '@vue/composition-api'

import store from '@/store'

export default {
  setup() {
    const isLoaderVisible = computed(() => store.getters['general/getLoaderState'])

    return {
      isLoaderVisible,
    }
  },
}
</script>

<style>
</style>
