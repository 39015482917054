export default [
  'Google',
  'TikTok',
  'Instagram',
  'Twitter',
  'Referral',
  'GitHub',
  'Ads',
  'AppSumo',
  'Facebook',
  'Medium',
  'LinkedIn',
  'YouTube',
]
