import { firestoreAction } from 'vuexfire'

import readIds from '@/composables/readIds'
import { db } from '@/firebase/config'

const defaultWorkspaceDetail = {
  Workspaces: {
    Community: {
      Name: 'Community',
      id: 'Community',
      TSApiKey:
        'UWd1QTZVb1dvUXFPR0NxZlM0eVVreWkzSEl0bXVXelZjcTYxWGtGMFJIVT1TR29xeyJmaWx0ZXJfYnkiOiJXb3Jrc3BhY2VJZDpDb21tdW5pdHkifQ==',
      Members: {
        '*': 'editor',
      },
    },
  },
  CurrentWorkspace: 'Community',
  CurrentWorkspaceId: 'Community',
  TotalNumber: 1,
}

export default {
  namespaced: true,
  state: {
    workspaceDetails: JSON.parse(JSON.stringify(defaultWorkspaceDetail)),
  },
  getters: {
    getWorkspaces(state) {
      return state.workspaceDetails
    },
    getCurrentWorkspace(state) {
      return state.workspaceDetails.CurrentWorkspace
    },
    getCurrentWorkspaceId(state) {
      return state.workspaceDetails.CurrentWorkspaceId
    },
  },
  mutations: {
    SET_WORKSPACES(state, payload) {
      payload.forEach(item => {
        state.workspaceDetails.Workspaces[item.Name] = item
        state.workspaceDetails.TotalNumber += 1
      })

      // console.log('state.workspaceDetails', state.workspaceDetails)
    },
    SET_CURRENT_WORKSPACE(state, payload) {
      state.workspaceDetails.CurrentWorkspace = payload

      // console.log(
      //   payload,
      //   state.workspaceDetails.Workspaces,
      //   'state.workspaceDetails.Workspaces[payload].id',
      //   state.workspaceDetails.Workspaces[payload],
      // )
      state.workspaceDetails.CurrentWorkspaceId = state.workspaceDetails.Workspaces[payload].id
      state.workspaceDetails.TotalNumber += 1
    },
    EDIT_WORKSPACE(state, payload) {
      state.workspaceDetails.Workspaces[state.workspaceDetails.CurrentWorkspace].Name = payload
    },
    RESET_ALL(state) {
      // console.log(defaultWorkspaceDetail, 'I am triggered Profile.Workspaces')
      state.workspaceDetails = JSON.parse(JSON.stringify(defaultWorkspaceDetail))
    },
  },
  actions: {
    bindWorkspaces: firestoreAction(({ rootState, commit }) => {
      const { Profile } = rootState.user

      if (Profile && Profile.Workspaces) {
        readIds(db.collection('Workspaces'), Profile.Workspaces).then(resp => {
          commit('RESET_ALL')
          commit('SET_WORKSPACES', resp)
        })
      }
    }),
  },
}
