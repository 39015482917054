export default [
  'Student',
  'Freelancer',
  'CTO',
  'Founder',
  'Frontend Developer',
  'Backend Developer',
  'Test Engineer',
  'DevOps Engineer',
  'Product Manager',
]
