<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiXml }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="500"
      class="app-customizer-drawer"
    >
      <!-- Heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6
          class="font-weight-semibold text-xl text--primary"
        >
          CUSTOM CODE
        </h6>
        <span class="text--secondary">Add code to codebase</span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- Section: Themeing -->
        <div class="customizer-section">
          <v-text-field
            v-model="description"
            outlined
            dense
            label="Description"
            hide-details
            required
            medium
          ></v-text-field>

          <v-card
            :height="`${codeBlockHeight-76}px`"
            class="mt-3"
          >
            <editor
              v-model="code"
              :highlight-lang="languages[currentLanguage]"
              :theme="'askjarvis'"
              name="custom-code-now"
            ></editor>
          </v-card>

          <v-card-actions class="pa-0 mt-3">
            <v-select
              v-model="currentLanguage"
              :items="Object.keys(languages).sort()"
              outlined
              hide-details
              class="select-router-transition"
              label="languages"
              clearable
              required
              dense
              :clear-icon="icons.mdiClose"
            >
            </v-select>

            <v-spacer></v-spacer>
            <v-btn
              color="success"

              @click="saveCode"
            >
              Save code
            </v-btn>
          </v-card-actions>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

import { mdiClose, mdiXml } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { auth, db, timestamp } from '@/firebase/config'

import useGlobalVariables from '@/globalVar'
import Editor from './Editor.vue'

import store from '@/store'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
    Editor,
  },
  setup() {
    const isCustomizerOpen = ref(false)

    const description = ref('')
    const code = ref('')
    const currentLanguage = ref('')

    const { languages } = useGlobalVariables()

    const codeBlockHeight = computed(() => store.getters['app/getContentHeight'])
    const currentWorkspaceId = computed(() => store.getters['workspaces/getCurrentWorkspaceId'])

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const saveCode = () => {
      // create a new doc in db.collection('Workspaces').doc(currentWorkspaceId).collection('Codebase') with description, code, currentLanguage
      store.commit('general/SET_LOADER_STATE', true)

      const codeBlock = {
        App: 'Custom Code',
        DisplayName: auth.currentUser.displayName,
        CreatedOn: timestamp.now(),
        FromLanguage: [currentLanguage.value, ''],
        Output: code.value,
        Query: description.value,
        Source: 'webapp',
        UserId: auth.currentUser.uid,
        WorkspaceId: currentWorkspaceId.value,
      }

      db.collection('Codebase')
        .add(codeBlock)
        .then(() => {
          store.commit('general/SET_LOADER_STATE', false)
          isCustomizerOpen.value = false
          store.commit('general/SET_SNACKBAR_MSG_STATE', 'Your code is saved')
        })
        .catch(() => {
          store.commit('general/SET_LOADER_STATE', false)
          store.commit('general/SET_ERROR_MSG', 'Error saving code, please try again or contact support')
        })
    }

    return {
      description,
      code,
      currentLanguage,

      languages,

      isCustomizerOpen,
      codeBlockHeight,

      // Perfect scrollbar options
      perfectScrollbarOptions,

      saveCode,

      // Icons
      icons: {
        mdiXml,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
