export default {
  namespaced: true,
  state: {
    basket: {
      items: {},
      total: 0.0,
      recurring: true,
      promocode: '',
    },
  },

  getters: {
    getBasket(state) {
      return state.basket
    },
  },
  mutations: {
    SET_BASKET(state, payload) {
      // const key = Object.keys(payload)[0]
      // if (state.basket.items[key]) {
      //   // state.basket.total -= state.basket.items[key].price
      //   return
      // }
      // state.basket.items[key] = payload[key]
      // state.basket.total += payload[key].price
      // state.basket.total = parseFloat(state.basket.total.toFixed(2))

      // add items to basket for every key in payload
      // console.log('add to basket payload is', payload)
      Object.keys(payload).forEach(key => {
        // console.log(key, '------------')
        state.basket.items[key] = payload[key]
        state.basket.total += payload[key].price
      })
      state.basket.total = parseFloat(state.basket.total.toFixed(2))

      // add decimal to only 2 places

      state.basket.recurring = payload[Object.keys(payload)[0]].term
    },
    REMOVE_BASKET(state, payload) {
      // delete key from basket
      state.basket.total -= state.basket.items[payload].price
      state.basket.total = parseFloat(state.basket.total.toFixed(2))
      delete state.basket.items[payload]
    },
    SET_PROMO_CODE(state, payload) {
      state.basket.promocode = payload
    },
    RESET_ALL(state) {
      state.basket = {
        items: {},
        total: 0.0,
      }
    },
  },
  actions: {},
}
